import React from 'react'
import { Link } from 'gatsby'
import './career-list.sass'

const categoriseCareers = (data) => {
  let result = data.reduce(function (r, a) {
    r[a.node.department] = r[a.node.department] || [];
    r[a.node.department].push(a);

    return r;
  }, Object.create(null));

  return result;
}

const getCareers = (data) => {
  let careers = categoriseCareers(data);

  return Object.keys(careers).map((career, i) => {
    return (
      <div key={i} className="Careers__list__department">
        <p className="border-bottom pb-3">{career}</p>
        <ul className="Careers__list__department__list mb-5">
          {careersByDep(careers[career])}
        </ul>
      </div>
    )
  })
}

const careersByDep = (depCareers) => {
  return depCareers.map((career, i) => {
    return (
      <li key={i} className="border-bottom py-2">
        <Link
          to={career.node.path}
          className="d-flex align-items-center justify-content-between"
        >
          {career.node.title}<span>{career.node.location}</span>
        </Link>
      </li>
    )
  })
}

const CareerList = ({ data, ...props }) => (
    <div className="Careers__list__wrapper">
      {getCareers(data)}
    </div>
)


export default CareerList
