import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Layout from 'components/layout'
import SEO from 'components/seo/seo'
import CareerList from 'components/careers/career-list'
import Image from "../components/image/image";
import LayoutSidePages from '../components/layout/side-pages'
import GetInTouch from 'components/get-in-touch/get-in-touch'
import './careers.sass'


class CareersPage extends Component {

  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Layout
        bodyClass="k-reverse-header Careers">
        <SEO
          title="Careers | Come work at one of the UK’s fastest growing AI Start-ups"
          keywords={['Kortical careers', 'AI careers', 'AI resources', 'ML careers', 'Artificial Intelligence']}
          description="Read the latest AI news and access our free resources and whitepapers."
        />
        <LayoutSidePages background="k-bg--grey pb-4">
          <h1 className="h2--new">Careers at Kortical</h1>
          <p className="mb-4 Careers__subheading">Are you ready for an exciting adventure in one of the most exciting ML start-ups in London? Look no further. We may just have the job for you.</p>
        </LayoutSidePages>
        <section className="Careers__team">
          <div className="container-fluid">
            <div className="Careers__team__row d-flex">
              <div className="Careers__team__row__content__container col-lg-6 col-12 pl-0 pr-0 mb-5">
                <Image image_url="logos/kortical-LGBT.svg"
                      className="Careers__team__row__icon mb-4"
                      alt="Kortical-icon" />
                <h4 className='h4-big'>The Kortical Team</h4>
                <p>We're a team of dedicated engineers, data scientists, sales, and operations from different nationalities and backgrounds. Our goal is to help businesses scale their machine learning capabilities with awesome tools and support.</p>
              </div>
              <div className='Careers__team__row__image col-lg-6 col-12'>
                <Image image_url="avatars/avatar-kortical-team/avatar-kortical-team.png"
                        alt="section icon"
                        className="w-100"/>
              </div>
            </div>
          </div>
        </section>
        <section className="Careers__list">
          <div className="container-fluid container-fluid--small k-section pt-0 px-4">
            <h2 className="text-center mb-4">Job Vacancies</h2>
            <CareerList data={this.props.data.allCareersYaml.edges}></CareerList>
          </div>
        </section>
        <section className="Careers__perks">
          <div className="container-fluid container-fluid--small k-section pt-0 px-0">
            <div className="Careers__perks-row d-flex">
              <div className="Careers__perks-content col-md-6 col-12 px-4">
                <Image image_url="content/career-perks-1/career-perks-1.png"
                      className="Careers__perks-icon mb-4"
                      alt="Kortical-icon" />
                <h4>Life at Kortical</h4>
                <p>We've got the foundation, and now it's time to grow. Our team members are curious, humble, and love to work together. We want everyone to thrive and be themselves at Kortical. We experiment, build killer products, and have fun!</p>
              </div>
              <div className="Careers__perks-content col-md-6 col-12 px-4">
                <Image image_url="content/career-perks-2/career-perks-2.png"
                      className="Careers__perks-icon mb-4"
                      alt="Kortical-icon" />
                <h4>Recruitment process</h4>
                <p>We start with a quick coffee or video call to get to know each other. Then we give every candidate a unique challenge to let you show off your skills. After that, you'll meet some team members to make sure that there's a good fit. Finally, you'll meet the CEO.</p>
              </div>
            </div>
            <div className="Careers__perks-row d-flex">
              <div className="Careers__perks-content col-md-6 col-12 px-4">
                <Image image_url="content/career-perks-3/career-perks-3.png"
                      className="Careers__perks-icon mb-4"
                      alt="Kortical-icon" />
                <h4>Day to day</h4>
                <p>You'll have plenty of responsibilities, but also lots of freedom. We believe in finding smarter ways to work, so take initiative and bring your experiences along. And we want you to be yourself. You're in this team because you're talented, full stop. Oh, and no dress code!</p>
              </div>
              <div className="Careers__perks-content col-md-6 col-12 px-4">
                <Image image_url="content/career-perks-4/career-perks-4.png"
                      className="Careers__perks-icon mb-4"
                      alt="Kortical-icon" />
                <h4>Flexible working</h4>
                <p>We enjoy both sides of working life, getting together in the office but also getting the job done at home.  Collaboration and debate work better in person for design sessions, strategy meetings and workshops but we also enjoy not having to commute and getting on with tasks using slack and zoom to sync up with each other.</p>
              </div>
            </div>
          </div>
        </section>
        <GetInTouch />
      </Layout>
    )
  }
}

export default CareersPage

export const pageQuery = graphql`
  query {
    allCareersYaml {
      edges {
        node {
          path
          date
          title
          location
          department
        }
      }
    }
  }
`
